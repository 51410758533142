<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-card class="elevation-12" :loading="loading">
              <v-form v-model="valid" :lazy-validation="false" @submit.prevent="_reset">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Reset Password</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    v-model="password"
                    :append-icon="pshow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="pshow ? 'text' : 'password'"
                    @click:append="pshow = !pshow"
                    :rules="rules.password"
                  ></v-text-field>

                  <v-text-field
                    label="Confirm Password"
                    name="confirm"
                    prepend-icon="mdi-lock"
                    v-model="confirm"
                    :append-icon="cshow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="cshow ? 'text' : 'password'"
                    @click:append="cshow = !cshow"
                    :rules="rules.password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="loading"
                    :disabled="!valid"
                  >Reset Password</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Auth from "@/store/models/Auth";

export default {
  components: {},
  data: function () {
    return {
      loading: false,
      pshow: false,
      cshow: false,
      password: "",
      confirm: "",
      rules: {
        password: [
          (v) => !!v || "Is required",
          (v) => (v || "").length >= 6 || "Min 6 characters",
          (v) => (v || "").length <= 20 || "Max 20 characters",
          (v) => this.$regexPwd.test(v) || this.$regexPwdTxt,
        ],
        confirm: [
          (v) => !!v || "Is required",
          (v) => (v || "").length >= 6 || "Min 6 characters",
          (v) => (v || "").length <= 20 || "Max 20 characters",
          (v) => this.$regexPwd.test(v) || this.$regexPwdTxt,
          () => this.password === this.confirm || "Password must match",
        ],
      },
      valid: false,
    };
  },
  computed: {},
  methods: {
    headers() {
      return {
        "X-Reset-Key": this.$route.params.key,
      };
    },
    _reset() {
      if (!this.valid) {
        return;
      }

      this.loading = true;
      const options = {
        headers: this.headers(),
        data: {
          password: this.password,
        },
      };

      Auth.resetPwd(options)
        .then(async (res) => {
          this.$router.push({ path: `/login` }).then(() => (this.loading = false));
          return res;
        })
        .catch((err) => {
          console.error("Reset Password error: ", err);
          this.loading = false;
          return err;
        });
    },
  },
};
</script>